<template>
  <div class="row mt-4">
    <div
      class="col-5 d-flex flex-column justify-content-center align-items-end vertical-spaceout"
    >
      <div v-html="$marked($t(brickIdFree))"></div>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      <font-awesome-icon
        :icon="['fas', icon]"
        style="font-size: 2.0rem;"
      ></font-awesome-icon>
    </div>
    <div
      class="col-5 d-flex flex-column justify-content-center align-items-start vertical-spaceout"
    >
      <div v-html="$marked($t(brickIdPremium))"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    brickIdFree: {
      type: String,
      required: true,
    },
    brickIdPremium: {
      type: String,
      required: true,
    },
  },
};
</script>
