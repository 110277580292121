<template>
  <div class="vertical-spaceout d-flex align-items-center mb-3">
    <div style="width: 40px;">
      <font-awesome-icon
        :icon="['fas', icon]"
        style="font-size: 2.0rem;"
      ></font-awesome-icon>
    </div>
    <div v-html="$marked($t(brickId))" class="ml-4"></div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    brickId: {
      type: String,
      required: true,
    },
  },
};
</script>
