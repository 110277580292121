<template>
  <div class="row">
    <div class="col-md-5 col-lg-6 mt-0 mt-md-5 mt-lg-0">
      <h2>{{ $t('premium.intro.title') }}</h2>

      <div v-html="$marked($t('premium.intro.text'))"></div>
    </div>
    <div class="col-md-7 col-lg-6 mt-4 mt-md-0">
      <div>
        <h2>
          {{ $t('premium.rates.title') }}
          <b-form-radio-group v-model="selectedCurrency" buttons>
            <b-form-radio value="euro">
              <font-awesome-icon
                :icon="['fas', 'euro-sign']"
                class="fa-fw"
              ></font-awesome-icon>&nbsp;
            </b-form-radio>
            <b-form-radio value="dollar">
              &nbsp;<font-awesome-icon
                :icon="['fas', 'dollar-sign']"
                class="fa-fw"
              ></font-awesome-icon>
            </b-form-radio>
          </b-form-radio-group>
          <b-form-radio-group v-model="selectedPay" buttons class="ml-2">
            <b-form-radio value="xsolla">
              Xsolla
            </b-form-radio>
            <b-form-radio value="paypal">
              Paypal
            </b-form-radio>
            <b-form-radio value="steam">
              Steam
            </b-form-radio>
          </b-form-radio-group>
        </h2>

        <p v-if="!isEuro" class="font-small">
          {{ $t('premium.rates.conversionNotice') }}
        </p>

        <div v-if="isXsolla" class="mb-3 pl-5 pl-sm-0">
          <div
            class="d-block d-sm-inline-block font-weight-bold"
            style="vertical-align: top;"
          >
            {{ $t('premium.rates.w1.title') }}:
          </div>
          <span class="d-inline-block pl-2 pr-2">
            <strong>{{ rates[selectedPay][selectedCurrency].w1.total }}</strong>
          </span>
        </div>

        <div class="mb-3 pl-5 pl-sm-0">
          <div
            class="d-block d-sm-inline-block font-weight-bold"
            style="vertical-align: top;"
          >
            {{ $t('premium.rates.m1.title') }}:
          </div>
          <span class="d-inline-block pl-2 pr-2">
            <strong>{{ rates[selectedPay][selectedCurrency].m1.total }}</strong>
          </span>
        </div>

        <div class="mb-3 pl-5 pl-sm-0">
          <div
            class="d-block d-sm-inline-block font-weight-bold"
            style="vertical-align: top;"
          >
            {{ $t('premium.rates.m3.title') }}:
          </div>
          <span class="d-inline-block pl-2 pr-2">
            <strong>{{ rates[selectedPay][selectedCurrency].m3.total }}</strong
            ><br />
            <span class="text-link-color">{{
              rates[selectedPay][selectedCurrency].m3.month
            }}</span>
            /{{ $t('premium.rates.perMonth') }}
          </span>
        </div>

        <div class="mb-3 pl-5 pl-sm-0">
          <div
            class="d-block d-sm-inline-block font-weight-bold"
            style="vertical-align: top;"
          >
            {{ $t('premium.rates.m6.title') }}:
          </div>
          <span class="d-inline-block pl-2 pr-2">
            <strong>{{ rates[selectedPay][selectedCurrency].m6.total }}</strong
            ><br />
            <span class="text-link-color">{{
              rates[selectedPay][selectedCurrency].m6.month
            }}</span>
            /{{ $t('premium.rates.perMonth') }}
          </span>
        </div>

        <hr class="border-secondary" />

        <div class="mb-3 pl-5 pl-sm-0">
          <div
            class="d-block d-sm-inline-block font-weight-bold"
            style="vertical-align: top;"
          >
            {{ $t('premium.rates.y1.title') }}:
          </div>
          <span class="d-inline-block pl-2 pr-2">
            <strong>{{ rates[selectedPay][selectedCurrency].y1.total }}</strong
            ><br />
            <span class="text-link-color">{{
              rates[selectedPay][selectedCurrency].y1.month
            }}</span>
            /{{ $t('premium.rates.perMonth') }}
          </span>
        </div>

        <div v-if="!isSteam" class="mb-3 pl-5 pl-sm-0">
          <div
            class="d-block d-sm-inline-block font-weight-bold"
            style="vertical-align: top;"
          >
            {{ $t('premium.rates.y99.title') }}:
          </div>
          <span class="d-inline-block pl-2 pr-2">
            <strong>{{ rates[selectedPay][selectedCurrency].y99.total }}</strong>
          </span>
        </div>

        <p v-if="!isSteam" class="font-small">
          {{ $t('premium.rates.lifetime') }}
        </p>

        <b-button
          :href="'https://me.ryzom.com/?lang=' + $i18n.locale"
          class="mt-4 text-uppercase"
          >{{ $t('premium.button.manageSub') }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedCurrency: 'euro',
      selectedPay: 'xsolla',

      rates: {
        xsolla: {
            euro: {
              w1: {
                total: '1.50 €',
                day: '0.00 €',
              },
              m1: {
                total: '3.90 €',
                month: '3.90 €',
              },
              m3: {
                total: '10.90 €',
                month: '3.63 €',
              },
              m6: {
                total: '19.90 €',
                month: '3.32 €',
              },
              y1: {
                total: '29.90 €',
                month: '2.49 €',
              },
              y99: {
                total: '299.90 €',
                month: '0.00 €',
              },
            },
            dollar: {
              w1: {
                total: '$ 1.59',
                day: '$ 0.22',
              },
              m1: {
                total: '$ 4.12',
                month: '$ 4.12',
              },
              m3: {
                total: '$ 11.52',
                month: '$ 3.84',
              },
              m6: {
                total: '$ 21.03',
                month: '$ 3.51',
              },
              y1: {
                total: '$ 31.60',
                month: '$ 2.63',
              },
              y99: {
                total: '$ 314.93',
                month: '$ 314.93',
              },
            },
        },
         paypal: {
            euro: {
              w1: {
                total: '0.00 €',
                day: '0.00 €',
              },
              m1: {
                total: '3.90 €',
                month: '3.90 €',
              },
              m3: {
                total: '10.90 €',
                month: '3.63 €',
              },
              m6: {
                total: '19.90 €',
                month: '3.32 €',
              },
              y1: {
                total: '29.90 €',
                month: '2.49 €',
              },
              y99: {
                total: '299.90 €',
                month: '0.00 €',
              },
            },
            dollar: {
              w1: {
                total: '$ 0.00',
                day: '$ 0.00',
              },
              m1: {
                total: '$ 4.12',
                month: '$ 4.12',
              },
              m3: {
                total: '$ 11.52',
                month: '$ 3.84',
              },
              m6: {
                total: '$ 21.03',
                month: '$ 3.51',
              },
              y1: {
                total: '$ 31.60',
                month: '$ 2.63',
              },
              y99: {
                total: '$ 314.93',
                month: '$ 314.93',
              },
            },
        },
         steam: {
            euro: {
              w1: {
                total: '0.00 €',
                day: '0.00 €',
              },
              m1: {
                total: '4.90 €',
                month: '4.90 €',
              },
              m3: {
                total: '13.90 €',
                month: '4.63 €',
              },
              m6: {
                total: '24.90 €',
                month: '4.15 €',
              },
              y1: {
                total: '39.90 €',
                month: '3.33 €',
              },
              y99: {
                total: '0.00 €',
                month: '0.00 €',
              },
            },
            dollar: {
              w1: {
                total: '$ 0.00',
                day: '$ 0.00',
              },
              m1: {
                total: '$ 5.18',
                month: '$ 5.18',
              },
              m3: {
                total: '$ 14.69',
                month: '$ 4.89',
              },
              m6: {
                total: '$ 25.32',
                month: '$ 4.39',
              },
              y1: {
                total: '$ 42.17',
                month: '$ 3.52',
              },
              y99: {
                total: '$ 0.00',
                month: '$ 0.00',
              },
            },
        },

      },
    };
  },
  computed: {
    isEuro() {
      return this.selectedCurrency === 'euro';
    },
    isXsolla() {
      return this.selectedPay === 'xsolla';
    },
    isSteam() {
      return this.selectedPay === 'steam';
    },
  },
};
</script>
