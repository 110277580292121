<template>
  <div>
    <div>
      <div class="pt-4 pb-4 pt-md-5 pb-md-5">
        <div class="container">
          <h1>{{ $t('premium.title') }}</h1>
        </div>
      </div>

      <div
        class="pt-4 pb-4 pt-md-5 pb-md-5 bg-section-gradient section-border-top section-border-bottom"
      >
        <div class="container">
          <Rates></Rates>
        </div>
      </div>

      <div class="pt-4 pb-4 pt-md-5 pb-md-5">
        <div class="container">
          <h1>
            {{ $t('premium.compare.title') }}
          </h1>
        </div>
      </div>

      <div class="">
        <div
          class="d-md-none bg-section-gradient section-border-top section-border-bottom pt-4 pb-4 pt-md-5 pb-md-5 list-invisible"
        >
          <div class="container">
            <div class="card bg-dark p-3">
              <h3 class="text-uppercase">
                {{ $t('premium.comparison.premium.title') }}
              </h3>
              <SingleFeatureRow
                icon="sort-amount-up"
                brick-id="premium.comparison.premium.maxlevel"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="tshirt"
                brick-id="premium.comparison.premium.maxquality"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="luggage-cart"
                brick-id="premium.comparison.premium.storage"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="dna"
                brick-id="premium.comparison.premium.xp"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="hands-helping"
                brick-id="premium.comparison.premium.support"
              ></SingleFeatureRow>

              <h3 class="text-uppercase">
                {{ $t('premium.comparison.free.title') }}
              </h3>
              <SingleFeatureRow
                icon="sort-amount-up"
                brick-id="premium.comparison.free.maxlevel"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="tshirt"
                brick-id="premium.comparison.free.maxquality"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="luggage-cart"
                brick-id="premium.comparison.free.storage"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="dna"
                brick-id="premium.comparison.free.xp"
              ></SingleFeatureRow>
              <SingleFeatureRow
                icon="hands-helping"
                brick-id="premium.comparison.free.support"
              ></SingleFeatureRow>
            </div>
          </div>
        </div>

        <div
          class="d-none d-md-block bg-section-gradient section-border-top section-border-bottom pt-4 pb-4 pt-md-5 pb-md-5 list-invisible"
        >
          <div class="container font-large">
            <div class="row font-xx-large">
              <div class="col-5 text-right text-uppercase">
                {{ $t('premium.comparison.free.title') }}
              </div>
              <div class="col text-center"></div>
              <div class="col-5 text-uppercase">
                {{ $t('premium.comparison.premium.title') }}
              </div>
            </div>

            <ComparisonRow
              icon="sort-amount-up"
              brick-id-free="premium.comparison.free.maxlevel"
              brick-id-premium="premium.comparison.premium.maxlevel"
            ></ComparisonRow>
            <ComparisonRow
              icon="tshirt"
              brick-id-free="premium.comparison.free.maxquality"
              brick-id-premium="premium.comparison.premium.maxquality"
            ></ComparisonRow>
            <ComparisonRow
              icon="luggage-cart"
              brick-id-free="premium.comparison.free.storage"
              brick-id-premium="premium.comparison.premium.storage"
            ></ComparisonRow>
            <ComparisonRow
              icon="dna"
              brick-id-free="premium.comparison.free.xp"
              brick-id-premium="premium.comparison.premium.xp"
            ></ComparisonRow>
            <ComparisonRow
              icon="hands-helping"
              brick-id-free="premium.comparison.free.support"
              brick-id-premium="premium.comparison.premium.support"
            ></ComparisonRow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rates from './Rates';
import ComparisonRow from './ComparisonRow';
import SingleFeatureRow from './SingleFeatureRow';

export default {
  components: {
    SingleFeatureRow,
    ComparisonRow,
    Rates,
  },
};
</script>
