<template>
  <html>
    <body class="not_ms">
      <Me v-bind="$props"></Me>
    </body>
  </html>
</template>

<script>
import VueI18n from 'vue-i18n';
import { BootstrapVue, ImagePlugin } from 'bootstrap-vue';

import Vue from 'vue';

import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons/faSortAmountUp';
import { faTshirt } from '@fortawesome/free-solid-svg-icons/faTshirt';
import { faLuggageCart } from '@fortawesome/free-solid-svg-icons/faLuggageCart';
import { faDna } from '@fortawesome/free-solid-svg-icons/faDna';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';

import marked from 'marked';
import langEN from './lang/en-US';
import langDE from './lang/de-DE';
import langFR from './lang/fr-FR';
import langES from './lang/es-ES';
import langRU from './lang/ru-RU';
import Me from './components/me/play';

Vue.use(VueI18n);
Vue.use(ImagePlugin);
Vue.use(BootstrapVue);
Vue.prototype.$marked = marked;

config.autoAddCss = false;
config.keepOriginalSource = false;

library.add(
  faSortAmountUp,
  faTshirt,
  faLuggageCart,
  faDna,
  faHandsHelping,
  faEuroSign,
  faDollarSign
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

export default {
  i18n: new VueI18n({
    locale: 'en',
    messages: {
      en: langEN,
      de: langDE,
      fr: langFR,
      es: langES,
      ru: langRU,
    },
  }),
  components: { Me },
  props: {
    lang: {
      type: String,
      default: 'en',
    },
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
};
</script>

<style lang="scss">
@import 'scss/common';

@import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

@import 'scss/layout/me';
</style>
